import React from "react"
// import { Link } from "gatsby"

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import Locator from "../components/locator"

import { graphql } from "gatsby"

import "../components/layout.css"

const SecondPage = ({data: {allDatoCmsClinic}}) => {
  return (
    <Locator clinics={allDatoCmsClinic.edges.flatMap(e => e.node)} />
  )
}

export const query = graphql`
  query AllClinicsQuery {
    allDatoCmsClinic {
      edges {
        node {
          id
          title
          address
          state
          location {
            latitude
            longitude
          }
        }
      }
    }
  }
`

export default SecondPage
