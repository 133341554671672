import React, {useState} from 'react';
import {fromJS} from 'immutable';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider, List, ListItem, ListItemText, AppBar, Tabs, Tab, Typography
} from '@material-ui/core';
import distance from '@turf/distance';
import Map from './map'
import CurrentLocationFinder from './CurrentLocationFinder';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  panel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'white'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const classes = useStyles();

  return (
    <Typography
      component="div"
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.panel}
      style={{ zIndex: value !== index ? 0 : 1 }}
      {...other}
    >
      {children}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Feature(props) {
  const { item, showDivider } = props;

  return (
    <>
      <ListItem>
        <ListItemText primary={item.get('title')} secondary={`${item.get('address')} · ${parseInt(item.get('distance'))} miles away`} />
      </ListItem>
      { showDivider && <Divider /> }
    </>
  );
}

export default ({clinics}) => {

  const classes = useStyles();

  const [hasPickedLocation, setHasPickedLocation] = useState(false);
  const [allClinics, setAllClinics] = useState(fromJS(clinics).sortBy(c => c.get('name')));
  const [selectedState, setSelectedState] = useState('');
  const [value, setValue] = React.useState(0);

  const handleStateSelected = state => {
    setHasPickedLocation(true);
    setSelectedState(state);
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleCurrentPositionPoint = currentPositionPoint => {
    setAllClinics(
      allClinics
        .map(c => c.set('distance', distance(currentPositionPoint, {
            "type": "Point",
            "coordinates": [ c.getIn(['location', 'longitude']), c.getIn(['location', 'latitude']) ]
          }, { units: 'miles' }))
        )
        .sortBy(c => c.get('distance'))
    );
  }

  let filteredClinics = allClinics.filter(f => (!selectedState || selectedState === f.get('state')));
  if (filteredClinics.size === 0) {
    filteredClinics = allClinics;
  }

  if (!hasPickedLocation) {
    return (
      <CurrentLocationFinder onStateSelected={handleStateSelected} selectedState={selectedState}
        onGetCurrentPosition={handleCurrentPositionPoint} options={allClinics.map(f => f.get('state')).toSet().toList()} />
    )
  } else {
    return (
      <div className={classes.root}>
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
          <div style={{position: 'absolute', top: 60, right: 20, zIndex: 2, backgroundColor: 'white'}}>
            <CurrentLocationFinder onStateSelected={handleStateSelected} selectedState={selectedState}
              onGetCurrentPosition={handleCurrentPositionPoint} options={allClinics.map(f => f.get('state')).toSet().toList()} />
          </div>
          <div>
            <AppBar position="static">
              <Tabs value={value} onChange={handleTabChange}>
                <Tab label="List" {...a11yProps(0)} />
                <Tab label="Map" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
          </div>
          <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <TabPanel value={value} index={0}>
              <div style={{maxHeight: '100%', overflow: 'auto'}}>
                <List>
                  { filteredClinics.map( (f, index, collection) => <Feature item={f} key={index} showDivider={index + 1 < collection.size} />) }
                </List>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Map data={filteredClinics} selectedState={selectedState} />
            </TabPanel>
          </div>
        </div>
      </div>
    );
  }
}
